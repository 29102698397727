#portal-logo {
  img {
    max-height: 2rem;
    width: auto;

    @include media-breakpoint-up(md) {
      max-height: 3.4rem;

    }
  }
}

#portal-languageselector {
  margin-left: 0;

  li {
    &.currentLanguage {
      display: none;
    }
  }
}

.navbar {
  --#{$prefix}navbar-background: #{$navbar-background};
  --#{$prefix}navlink-highlight-color: #{$nav-link-color};

  @include media-breakpoint-up(lg) {
    --#{$prefix}navbar-background: #{$zee-gray};
    --#{$prefix}navlink-highlight-color: #{$zee-yellow};
  }

  background-color: var(--#{$prefix}navbar-background);

  .navbar-nav {
    --#{$prefix}navbar-nav-link-padding-y: 0.75rem;
    --#{$prefix}navbar-nav-link-padding-x: 0.3rem;
    li {
      &.inPath>a {
        background-color: transparent;
      }
      a.state-private {
        color: #{$state-private-color};
      }
      &.d-block {
        display: block !important;
      }
      @include media-breakpoint-up(md) {
        &.d-md-none {
          display: none !important;
        }
      }
    }
    @extend .flex-grow-1;
    text-align: center;
    .nav-item {
      @extend .flex-grow-1;
    }
  }
} 

.navbar-simple {
  --#{$prefix}navbar-background: unset;

  .navbar-nav {
    li {
      &.inPath>a {
        background-color: transparent;
      }
      a.state-private {
        color: #{$state-private-color};
      }
    }
    @extend .flex-grow-1;
    text-align: center;
    .nav-item {
      @extend .flex-grow-1;
    }
  .nav-item {
    --#{$prefix}nav-link-font-weight: 600;

    a.nav-link {
      --#{$prefix}navbar-nav-link-padding-y: 0.25rem;
      --#{$prefix}navbar-nav-link-padding-x: 0.5rem;
      padding: var(--#{$prefix}navbar-nav-link-padding-y) var(--#{$prefix}navbar-nav-link-padding-x);
      font-weight: var(--#{$prefix}nav-link-font-weight);
      color: #{$nav-link-color};
      &:hover {
        color: #{$nav-link-color};
      }
    }
    @include media-breakpoint-up(lg) {

      &:not(:first-child) {
        border-left: 2px solid black;
      }
    }
  }  
  @include media-breakpoint-up(lg) {
    --#{$prefix}navlink-highlight-height: 2px;
  }

  background-color: unset;
}
}


// navbar animated highlighting
.nav-item {
  @include media-breakpoint-up(lg) {

    &:not(:first-child) {
      border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
    }
    &::after {
      transition: ease all 0.3s;
      content: "";
      height: var(--#{$prefix}navlink-highlight-height, 2px);
      background: var(--#{$prefix}navlink-highlight-color);

      @include media-breakpoint-up(lg) {
        height: var(--#{$prefix}navlink-highlight-height, 3px);
      }

      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
    }

    &:hover {
      transition: ease all 0.3s;

      &::after {
        transition: ease all 0.3s;
        width: 100%;
      }
    }
  }
  &.current {
    &::after {
      transition: ease all 0.3s;
      width: 100%;
    }
  }
}
.portal-searchbox {
  .search-btn {
    cursor: pointer;
    content: url('../img/search-ic.svg');
    position: absolute;
    transform: none;
    left: 16px;
    height: 23px;
    margin: 5px 0 0 4px;
    padding: 0.1rem !important;
    @include media-breakpoint-up(lg) {
      transform: translate(0,-50%) scale(0.9);
      top: 50%;
      left: 5px;
      height: 60%;
      margin: 0;
    }
  }
  .form-control {
      padding-left: 30px !important;
  }
}

//
.plone-toolbar-main .nav-item a.nav-link,
.plonetoolbar-contenttype a.nav-link,
.plonetoolbar-multilingual a.nav-link,
.plonetoolbar-workfow-transition a.nav-link,
.plonetoolbar-display-view a.nav-link,
.plonetoolbar-portlet-manager a.nav-link,
.plonetoolbar-content-action a.nav-link,
.personaltools-wrapper a.nav-link{
  font-size: 0.85em;
}