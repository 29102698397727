// bootstrap vars could be used here
// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss#L1515
.breadcrumb {
  margin: 20px 0px;
  background-color: transparent;

  a.breadcrumb-link {
    display: inline-block;
    position: relative;
    font-weight: 100;
    text-decoration: none;
    color: $black;
    font-size: $small-font-size;

    &:not(:last-child) {
      &:after {
        content: ' | ';
        position: relative;
        color: $black;
        top: 0px;
        left: 0px;
        display: inline-block;
        vertical-align: top;
        padding: 0px 0px 0px 2px;
      }
    }

    &.active {
      font-weight: 800;
    }
  }
}