#portal-footer-wrapper {
  // background: @blue;
  background: $primary;
  color: white;
  position: relative;
  padding: 40px 0px 35px;
  text-align: unset;

  .line {
    position: relative;
    // height: 330px;
    margin-bottom: 45px;
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    //   @media @m-m{
    //     height: 320px;
    //   }
    //   @media @m-s{
    //     height: auto;
    //     border: none;
    //   }
  }

  .f-menu {
    .f-menu-link {
      padding: 1px 0px;

      a {
        font-weight: 100;
        display: block;
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      //     @media @m-s{
      //       padding: 4px 0px;
      //     }
    }

    //   @media @m-xs {
    //     padding-left: 16px;
    //     padding-bottom: 16px;
    //   }
  }

  .company-info {
    div {
      margin: 2px 0px;
      .btn-yellow {
        color: $zee-aubergine;
        display: inline;
      }
      a {
        font-weight: 100;
        display: block;
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    div.company-info-mobile {
      margin: 2px 0px 2px 15px;
    }

    .info-h {
      font-size: 16px;
      margin: 0px;
      font-weight: 800;
      // @media @m-991{
      //   font-size: 15px;
      // }
    }

    .info-txt {
      font-size: 15px;
      font-weight: 100;
      // @media @m-991{
      //   font-size: 13px;
      // }
    }

  }

  .f-contact-form {
    position: relative;
    font-weight: 100;
    padding-left: 16px;

    .f-contact-h {
      font-size: 16px;
      font-weight: 800;
    }

    textarea,
    input {
      padding-left: 12px;
      height: 2.0em;
      display: inline-block;
      position: relative;
      width: 100%;
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.6);
      color: white;

      &::placeholder {
        color: white;
      }

      &.submit {
        font-weight: 100;
        display: inline-block;
        width: auto;
        border: none;
        height: auto;
      }
    }

    input[name=subject] {
      display: none;
    }

    textarea {
      padding: 8px 12px;
      height: 100px;
    }

    .msgarea {
      margin-bottom: 0.8em;
      font-size: 0.95em;
    }

    .form-group {
      margin-bottom: 10px;
      //  @media @m-s{
      //   margin: 16px 0px;
      //    font-size: 1.2em;
      // }
    }
  }

  .f-contact-links {
    padding-left: 12px;
    font-size: 0.95em;
    color: rgba(255, 255, 255, 0.6);

    a:visited,
    a:link {
      color: rgba(255, 255, 255, 1.0);
    }
  }

  .f-logo {
    margin: 10px 0px 35px;
    text-align: center;

    img {
      max-width: 100%;
      // @media @m-s{
      //   max-width: 70%;
      // }
    }

  }

  .f-social-wrapper {
    margin: 0px 15px 0px;
  }

  .f-social {
    .social-h {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 100;

      // @media @m-991{
      //   font-size: 12px;
      // }
      // @media @m-s {
      //   font-size: 16px;
      // }
      strong {
        font-weight: 800;
      }
    }
  }

  .soc-img {
    a {
      position: relative;
      top: 0px;
      transition: ease all 0.3s;
      text-decoration: none;

      &:hover {
        top: 3px;
      }
    }

    img {
      width: 40px;
      margin: 5px 5px;
      width: 40px;
      margin: 10px 25px !important;

      @include media-breakpoint-up(md) {
        width: 35px;
        margin: 15px 10px !important;

      }

      @include media-breakpoint-up(lg) {
        margin: 0px 2px;
        width: 35px;
      }
    }
  }

  #portal-colophon {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin: 0 auto;
    right: 0px;
    padding: 10px;
    text-align: center;
    //background: rgba(255,255,255,0.3);
    background-color: $zee-purple;
    font-weight: 100;
    font-size: 15px;

    //   @media @m-991{
    //     font-size: 13px;
    //   }
    p {
      padding: 0px;
      margin: 0px;
    }

    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    ;

    a:hover {
      color: lightgray;
    }
  }
}

//footer

// Hide the footer contact form when an easyform is displayed as main content

body.portaltype-easyform footer .f-contact-form {
  display: none;
}