// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides or theme specific variables here
@import "theme_variables_base";  // Bootstrap defaults for reuse
@import "theme_variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.dark.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.properties";
@import "@plone/plonetheme-barceloneta-base/scss/variables.barceloneta";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "theme_maps";

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.portlets.plone";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.font.plone";
@import "@plone/plonetheme-barceloneta-base/scss/root_variables";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/helpers";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// bootstrap helpers fuck up wistia videos, but we need clearfix for summary_listing.
@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}
.clearfix {
    @include clearfix();
}


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// barceloneta base
@import "@plone/plonetheme-barceloneta-base/scss/alerts";
@import "@plone/plonetheme-barceloneta-base/scss/forms";
@import "@plone/plonetheme-barceloneta-base/scss/controlpanels";
@import "@plone/plonetheme-barceloneta-base/scss/login";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";
// @import "@plone/plonetheme-barceloneta-base/scss/grid";
@import "@plone/plonetheme-barceloneta-base/scss/content_base";

// barceloneta full
@import "@plone/plonetheme-barceloneta-base/scss/cards";
@import "@plone/plonetheme-barceloneta-base/scss/scaffolding";
@import "@plone/plonetheme-barceloneta-base/scss/icons";
@import "@plone/plonetheme-barceloneta-base/scss/header";
@import "@plone/plonetheme-barceloneta-base/scss/sitenav";
@import "@plone/plonetheme-barceloneta-base/scss/breadcrumbs";
@import "@plone/plonetheme-barceloneta-base/scss/content";
@import "@plone/plonetheme-barceloneta-base/scss/comments";
@import "@plone/plonetheme-barceloneta-base/scss/portlets";
@import "@plone/plonetheme-barceloneta-base/scss/footer";

@import "@plone/plonetheme-barceloneta-base/scss/print";

@import "@plone/plonetheme-barceloneta-base/scss/roboto-webfont";

// 8. Theme specific styles
@import "theme_styles";
@import "header";
@import "breadcrumbs";
@import "content";
@import "mosaic";
@import "social_share";
@import "footer";
@import "subpage_header";
@import "print";
