// Helpers

// Mixin for yellow line effect
@mixin y-line-after($height, $width, $top, $left) { // mixin of the header line -> implement
  position: relative;
  &:after{
    transition: ease all 0.3s;
    content: '';
    position: absolute;
    top: $top;
    width: $width;
    height: $height;
    background: $zee-yellow;
    left: $left;
  }
}

// Mosiac

// Fix for old mosiac classes in existing content
.mosaic-width-half {
  @extend .col-12;
  @extend .col-md-6;
}
.mosaic-width-third {
  @extend .col-12;
  @extend .col-md-6;
  @extend .col-xl-4;
}
.mosaic-width-quarter {
  @extend .col-12;
  @extend .col-md-6;
  @extend .col-xl-3;
}

// Change color of dark background row
.mosaic-grid-row.mosaic-grid-row-dark {
  background: $zee-gray;
  color: $zee-aubergine;
}

.mosaic-grid-row-fluid .mosaic-grid-cell {
  padding: 0px;
}

/* Flip tile side tool label inside the tile because fluid puts the tile left border on the viewport edge */
.mosaic-tile-side-tools {
  left: 0px;
  .mosaic-tile-label {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

// Tiles

div.mosaic-plone\.app\.standardtiles\.html-tile,
div.mosaic-zeelandia\.tiles\.form-tile {
  display: flex;
  flex-grow: 1;
  margin-top: 32px;
  font-weight: 100;
  .mosaic-tile-content {
    width: 100%;
    h1,h2,h3 {
      font-weight: 100;
      strong {
        font-weight: 800;
      }
    }
  }
}

// Big title tile
.mosaic-zeelandia\.tiles\.big_title-tile {
  .text-section {
    text-align: center;
    margin: 5px;
    h2 {
      @extend .display-1;
      font-size: 45px;
      margin: 40px 0px 5px 0px;
      @include media-breakpoint-up(sm){
        letter-spacing: -2px;
        font-size: 50px;
      }
      @include media-breakpoint-up(md){
        letter-spacing: -3px;
        font-size: 60px;
      }
      @include media-breakpoint-up(lg){
        letter-spacing: -3px;
        font-size: 65px;
      }
      @include media-breakpoint-up(xl){
        letter-spacing: -6px;
        font-size: 90px;
        margin: 60px 40px 5px 40px;
      }
      @include media-breakpoint-up(xxl){
        letter-spacing: -7px;
        font-size: 100px;
        margin: 80px 60px 5px 60px;
      }
    }
    p {
      @extend .fs-5;
      max-width: 1080px;
      margin: 0px 30px 15px 30px;
      @include media-breakpoint-up(sm){
        margin: 0px 50px 25px 50px;
      }
      @include media-breakpoint-up(md){
        margin: 0px 65px 25px 65px;
      }
      @include media-breakpoint-up(lg){
        margin: 0px 90px 30px 90px;
      }
      @include media-breakpoint-up(xl){
        margin: 0px 120px 40px 120px;
      }
      @include media-breakpoint-up(xxl){
        margin: 0px 220px 60px 220px;
      }
    }
  }
}

// News tile
.mosaic-zeelandia\.tiles\.newstile-tile {
  margin: 2rem 0;
  .feature-wrapper {
    height: 100%;
    background-color: $zee-gray; 
    .feature-img {
      height: 100%;
      overflow: hidden;
    }
    .row {
      height: 100%;
    }
    .card-img-top{
      object-fit: cover;
      overflow: hidden;
      height: 200px;
      @include media-breakpoint-up(lg) {
        height: auto;
      }
    }
    &.large {
      background: white;
      .feature-img {
        height: 341px;
      }
      .feature-h{
        line-height: 26px;
        font-size: 26px;
        position: relative;
        @include media-breakpoint-up(md){
          font-size: 28px;
          line-height: 28px;
        }
        @include media-breakpoint-up(xl){
          font-size: 30px;
          line-height: 30px;

        }
        @include media-breakpoint-up(xxl){
          font-size: 40px;
          line-height: 40px;
        }
      }
      .feature-txt{
        margin-top: 16px;
        margin-bottom: 16px;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 100;
          @include media-breakpoint-up(lg){
            font-size: 16px;
          }
        }
      }
      .feature-link{
        text-align: right;
      }
    }
    &.special {
      background-color: $zee-yellow;
      .card-body {
        .feature-h {
          &::after {
            background: white;
          }
        }
      }
      .card-footer {
        margin-right: 16px;
        .btn {
          @extend .btn-animated;
          background: $zee-aubergine;
          &::after {
            background: url('../img/arrow-w.svg') no-repeat;
            background-position: center;
            background-size: contain;
            height: 16px;
            width: 12px;
          }
          color: white;
          &:hover {
            background: $zee-aubergine;
            color: white;
          }
        }
      }   
    }

    .card-body {
      height: 100%;
      .feature-h {
        position: relative;
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 16px;
        padding-top: 16px;         
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 4px;
          background: #ffe400;
          width: 57px;
        }
        @include media-breakpoint-up(sm){
          font-size: 28px;
          line-height: 28px;
        }
        @include media-breakpoint-up(md){
          font-size: 30px;
          line-height: 30px;
        }
        @include media-breakpoint-up(lg){
          font-size: 32px;
          line-height: 32px;
        }
      }
      .card-footer {
        margin-right: 8px;
      }  
    }
  }
}

// Carousel slider tile


.carousel {
  .carousel-inner {
    @extend .g-0;
    .carousel-item {
      img {
        height: auto;
        min-height: 150px;
        width: 100%;
        min-width: 432px;
      }

      .slide-content-wrapper{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 43%;
      z-index: 1;
      text-align: center;
      margin:  0 !important;
      display: inline-block;
      @include media-breakpoint-up(md){
        top: 10%;
        left: 6%;
        height: 240px;
        width: auto;
        max-width: unset;
      }

      @include media-breakpoint-up(lg){
        top: 8%;
        left: 8%;
        height: 240px;

      }

      .content-h{
        background: $zee-yellow-trans;
        padding: 15px 15px 15px 15px;
        line-height: 34px;
        font-size: 24px;
        letter-spacing: -1px;
        text-align: left;
        font-weight: 100;
        height: 100%;
        margin: 0px;
        @include media-breakpoint-up(sm){
          padding: 30px 20px 30px 30px;
        }
        @include media-breakpoint-up(md){
          padding: 15px 20px 20px 15px;
        }
        @include media-breakpoint-up(lg){
          padding: 20px 30px 30px 20px;

        }
        @include media-breakpoint-up(xl){
          height: auto;
          padding: 30px 40px 40px 30px;
          margin-top: 20px;
        }
        @include media-breakpoint-up(xxl){
          margin-top: 15px;
        }
        h1 {
          font-size: 28px;
          font-weight: 200;
          letter-spacing: -1px;
          @include media-breakpoint-up(sm){
            letter-spacing: 0px;
            line-height: 42px;
            font-size: 30px;
          }
          @include media-breakpoint-up(md){
            letter-spacing: -1px;
            line-height: 38px;
            font-size: 31px;
          }
          @include media-breakpoint-up(lg){
            line-height: 43px;
            font-size: 38px;
          }
          @include media-breakpoint-up(xl){
            line-height: 64px;
            font-size: 56px;
          }
          @include media-breakpoint-up(xxl){
            line-height: 72px;
            font-size: 62px;
          }
          strong{
            font-weight: 800;
            letter-spacing: -1px;
          }
        }
      }
      .content-desc{
        position: absolute;
        right: 7px;
        bottom: 7px;
        max-width: 200px;
        font-size: 16px;
        background: white;
        font-weight: 100;
        letter-spacing: -1px;
        text-align: center;
        padding: 0;
        @include media-breakpoint-up(md){
          max-width: 330px;
          right: -320px;
          padding: 10px;
          bottom: 28px;
        }
        @include media-breakpoint-up(lg){
          right: -341px;
          padding: 20px 20px 0 20px;
          bottom: -51px;
          font-size: 19px;
          max-width: 345px;
        }
        @include media-breakpoint-up(xl){
          right: -384px;
          bottom: -85px;
          max-width: 400px;
          font-size: 21px;

        }
        @include media-breakpoint-up(xxl){
          right: -414px;
          bottom: -143px;
          max-width: 455px;
          font-size: 23px;
        }
        .content-txt{
          padding: 0px;
          margin: 0px;
          display: none;
          @include media-breakpoint-up(md){
            display: block;
          }
        }
        > p {
          display: none;
          @include media-breakpoint-up(md){
            display: block;
          }
          strong{
            font-weight: 800;
          }
        }
      }
      .content-link{
        font-weight: 100;
        text-align: right;
        a {
          text-decoration: none;
        }
        p {
          margin:0.1rem;
          @include media-breakpoint-up(lg){
            margin: 0.5rem;
          }
        }
      }

      .slider-nav{
        position: absolute;
        bottom: 20px;
        right: -190px;
        float: right;
        text-align: center;
        .nav-btn{
          margin: 3px;
          display: inline-block;
          width: 20px;
          height: 20px;
          border: none;
          background: rgba(255,228,0,.3);
          transition: ease all 0.3s;
          &.active{
            background: $zee-yellow;
          }
        }
        @include media-breakpoint-up(md){
          position: relative;
          right: 0px;
          bottom: 0px;
          float: left;
        }
      }
    }
  }
}
}

// Embed tile
.mosaic-zeelandia\.tiles\.embed-tile {
  .mosaic-tile-content {
    padding: 0px;
    padding-bottom: 30px;
    .ratio {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}


// Two features tile
.mosaic-tile-content .two-features-section{
  padding: 40px 0px;
  background: $zee-gray;
  text-align: center;
  position: relative;
  .feature-wrapper{
    height: auto;
    text-align: left;
    padding: 7px;
    background: transparent;
    margin: 10px auto;
    @include media-breakpoint-up(lg){
      margin: 35px auto;
    }
    &.special{
      background: $zee-yellow;
      .feature-h:after{
        background: white;
      }
      .feature-desc-wrapper {
        flex-grow: 1;
      }
    }
    &.large{
      background: white;
      .feature-desc-wrapper {
        width: 100%;
        height: auto;
        vertical-align: top;
        overflow: hidden;
        max-height: none;
        @include media-breakpoint-up(lg) {
          height: 100%;
        }
      }
      .feature-h{
        line-height: 26px;
        font-size: 26px;
        margin: 20px 0px 5px 10px;
        position: relative;
        @include media-breakpoint-up(md){
          font-size: 28px;
          line-height: 28px;
        }
        @include media-breakpoint-up(xl){
          font-size: 30px;
          line-height: 30px;
          margin: 20px 0px 5px 20px;

        }
        @include media-breakpoint-up(xxl){
          font-size: 40px;
          line-height: 40px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 4px;
          background: #ffe400;
          width: 57px;
        }
      }
      .feature-txt{
        flex-grow: 1;
        margin-top: 16px;
        margin-bottom: 16px;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 100;
          @include media-breakpoint-up(lg){
            font-size: 16px;
          }
        }
      }
    }
  }
  .feature-img{
    width: 100%;
    img{
      object-fit: cover;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  .feature-category{
    padding-top: 20px;
    font-size: 15px;
  }
  .feature-h{
    font-weight: 100;
    font-size: 26px;
    letter-spacing: -1px;
    position: relative;
    letter-spacing: -1px;
    padding: 15px 0px 5px 0px;
    line-height: 26px;
    margin: 10px 0px 10px 10px;
    @include media-breakpoint-up(md){
      font-size: 26px;
      line-height: 28px;
      margin: 20px 0px 5px 0px;
    }

    @include media-breakpoint-up(lg){
      font-size: 30px;
      line-height: 30px;
      margin: 30px 0px 5px 10px;
    }
    @include media-breakpoint-up(xl){
      line-height: 32px;
      font-size: 32px;
      margin: 30px 0px 5px 20px;
    }
    strong{
      font-weight: 800;
    }
    &::after{ //xxxmixin yellow line
      left: 0px;
      content: '';
      position: absolute;
      top: 0px;
      height: 4px;
      background: #ffe400;
      width: 57px;
    }
  }
  .feature-txt {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 100;
    margin: 0px 5px 0px 10px;
    p {
      margin:0;
    }
    @include media-breakpoint-up(lg){
      font-size: 16px;
      margin: 0px 10px 0px 20px;
    }
  }
  .feature-link{
    left: inherit;
    right: 0px;
    bottom: 0px;
    margin: 10px 0px 10px 10px;
    @include media-breakpoint-up(sm){
      text-align: right;
    }
  }
}


// Link tile
.service-links-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;

  .service-link {
    color: $zee-aubergine;
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.7);
    padding: 7px 10px 3px 75px;
    margin: 5px auto;
    position: relative;
    text-decoration: none;


    &:hover {
      text-decoration: none;

      &:before {
        left: 10px;
      }

      .link-h:after {
        width: 45px;
        left: 0px;
      }
    }

    &:last-child {
      padding: 7px 10px 7px 75px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    &:before {
      transition: ease all 0.3s;
      content: url('../img/arrow-left.png');
      position: absolute;
      top: 13%;
      left: 0px;

    }

    .link-h {
      font-weight: 200;
      font-size: 17px;
      margin: 2px 0px 0px;
      padding: 0px;
      letter-spacing: -1px;
      strong {
        font-weight: 800;
      }
      @include y-line-after(4px, 0px, -4px, 0px);
      @include media-breakpoint-up(sm){
        font-size: 20px;
        @include y-line-after(2px, 0px, -4px, 0px);
      }
      @include media-breakpoint-up(lg){
        font-size: 25px;
      }
      @include media-breakpoint-up(xl){
        font-size: 28px;
      }
    }
    .link-txt {
      font-size: 13px;
      line-height: 16px;
      font-weight: 100;
      margin: 0px;
      padding: 0px;
      @include media-breakpoint-up(md){
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}