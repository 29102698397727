// Bootstrap defaults needed to work/compile

// Color system
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-size-base:              1rem !default; 
// $h1-font-size:                           $font-size-base * 2.5 !default;
// $h2-font-size:                           $font-size-base * 2 !default;
// $h3-font-size:                           $font-size-base * 1.75 !default;
$h4-font-size:                           $font-size-base * 1.25 !default;
// $h5-font-size:                           $font-size-base * 1.25 !default;
// $h6-font-size:                           $font-size-base !default;
