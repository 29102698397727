$enable-roboto-webfont:                     false;
$enable-rounded:                            false;
$enable-shadows:                            false;
$enable-gradients:                          false;

// VARIABLES
$zee-aubergine:                             rgba(29,5,60,1);
$zee-yellow:                                rgba(255, 228, 2,1);
$zee-yellow-trans:                          rgba(255, 228, 2,0.8);
$zee-gray:                                  rgba(235,234,241,1);
$zee-green:                                 rgba(152, 160, 32,1);

$zee-purple:                                rgb(101,81,118);
$zee-lavender:                              rgba(178,160,205,1);

$zee-light-aubergine:                       rgba(29,5,60,1);

$primary:                                   $zee-aubergine;
$secondary:                                 $zee-gray;

$body-color:                                $zee-aubergine;
$link-color:                                $primary;
$headings-color:                            $primary;

$navbar-nav-link-padding-y:                 .5rem;
$navbar-nav-link-padding-x:                 1rem;
$nav-link-font-size:                        16px;
$nav-tabs-border-color:                     $gray-500;
$nav-link-color:                            $gray-900;
$navbar-light-color:                        $gray-900;
$navbar-light-active-color:                 rgba($black, 1);
$navbar-light-hover-color:                  rgba($black, 1);
$navbar-background:                         $zee-yellow;
$navbar-light-active-background:            rgba($black, 0);
$navbar-light-hover-background:             rgba($black, 0);


$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$input-btn-font-size:                       0.875rem;
$font-weight-bolder:                        800;
$font-weight-base:                          $font-weight-light;
$headings-font-weight:                      $font-weight-normal;


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');


$card-cap-bg:                               #ebeaf1;

$table-border-color:                        $primary;
$table-cell-padding-x:                      0;
$table-cell-padding-x-sm:                   0;

