article>header {
  margin-bottom: 1rem;
}

.lead {
  margin-bottom: 1rem;
}

// * {
//   outline: 1px dotted pink;
// }

.detail-section {
  @extend .pt-4;

  section {
    @extend .mb-4;
  }

  h2 {
    font-weight: $font-weight-bolder;
  }
}

.btn {
  @extend .btn;
  p {
    margin: 0;
  }
}

.btn-animated {
  transition: ease all 0.3s;
  position: relative;
  white-space: nowrap;
  &::after {
    transition: ease all 0.3s;
    position: absolute;
    content: '';
    opacity: 0;
    top: 50%;
    transform: translate(0, -50%);
    right: 0em;
    height: 18px;
    width: 16px;
    --svg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>")
  }
  &:hover {
    padding-right: 1.5em;
    &::after {
      right: 0;
      opacity: 1;
    }
  }
}
.btn-aubergine {
  color: white;
  background-color: $zee-aubergine;
  &::after {
    background-color: white;
    mask: var(--svg);
  }
}
.btn-yellow {
  color: $zee-aubergine;
  background-color: $zee-yellow;
  &::after{
    background-color: $zee-aubergine;
    mask: var(--svg);
  }
}
.btn-white {
  color: $zee-aubergine;
  background-color: white;
  border-color: white;
  &::after{
    background-color: $zee-aubergine;
    mask: var(--svg);
  }
}
.cta-button,
.cta-button-purple {
  @extend .btn;
  @extend .btn-animated;
  @extend .btn-aubergine;
}
.cta-button-yellow {
  @extend .btn;
  @extend .btn-animated;
  @extend .btn-yellow;
}
.cta-button-yellow-big {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-animated;
  @extend .btn-yellow;
}
.cta-button-white {
  @extend .btn;
  @extend .btn-animated;
  @extend .btn-white; 
}
.userrole-authenticated {
  .recipes-section .section-state-private {
    border: 2px dotted #{$state-private-color} !important;
  }
  .recipes-section .section-state-pending {
    border: 2px dotted #{$state-private-color} !important;
  }

}
.card {
  .card-header {
    font-weight: $font-weight-semibold;
  }
  .card-body {
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  &.lightgrey {
    .card-img-top {
      @extend .bg-light;
      @extend .pt-2;
      @extend .px-2;
    }
    .card-body {
      @extend .bg-light;
      @extend .px-2;
    }
    .card-footer {
      @extend .bg-light;
      @extend .px-2;
      @extend .pb-2;
    }
  }
}


.content-card {
  --#{$prefix}card-spacer-y: calc(#{$spacer} * 1.5);
  --#{$prefix}card-spacer-x: 0;
  --#{$prefix}card-title-spacer-y: 0;
  --#{$prefix}card-border-width: 0;
  --#{$prefix}card-cap-padding-y: calc(#{$spacer} * 0.25);
  --#{$prefix}card-cap-padding-x: 0;
  --#{$prefix}card-cap-bg: rgba($black, 0);
  a {
    text-decoration: none;
  }
  .card-body {
    padding-bottom: 1rem;
    .subject-image{
      float: right;
      height: 60px;
      padding: 1px;
    }    
  }
  .card-title {
    @extend .fs-4;
    @extend .position-relative;
    font-size: 1.2rem;
    padding: 0.4rem 0.1rem 0.4rem 0.1rem;
    &::after {
      left: 0;
      content: "";
      position: absolute;
      top: -0.5rem;
      height: 0.25rem;
      background: $zee-yellow;
      width: 2rem;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.6rem;
    }
  }
  .card-text {
    font-size: 0.95rem;
  }

  .card-footer {
    @extend .d-flex;
    @extend .justify-content-end;
  }

  &.special {
    @extend .bg-yellow;
  }
}

.documentByLine {
  font-size: 0.875rem;
}

.detail-bottom {
  margin: 0px auto;
  border-top: 1px solid $black;
  padding-top: 20px;
  position: relative;
  text-align: right;

  .recipes-link {
    float: left;

    a {
      text-decoration: none;
      color: $black;
    }
  }

  .recipes-link,
  .social-wrapper,
  .print-btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 0px auto;
  }

  .print-btn {
    margin-right: 10px;
  }

  .social-wrapper {
    background: $zee-gray;
    padding: 7px 10px;
  }

}

// Backlink to previous page in content social footer only for products and recipes.
// disable > .btn animation because it points the other way, too weird for a back link
.overview-link {
  display: none;

  a {
    vertical-align: middle;
  }
}

.overview-link .btn.yellow {
  margin: 10px 0 0 16px;

  &:after {
    background: initial;
    transition: none;
    transform: none;
  }
}

.overview-link .btn:after {
  background: initial;
  transition: none;
  transform: none;

}

.recipe-detail-section, .product-detail-section{
  background: white;
  font-weight: 100;

  .detail-wrapper{
    margin-top: 10px;
  }
  .recipes {
    .card {
      margin-bottom: 20px;
      .recipe-link {
        margin-top: -20px;    
    }
    }
  }

  .recipe-h{
    margin: 20px auto 30px;
  }
  h2.recipe-h{
    margin: 20px auto 30px;
    font-weight: 800;
    font-size: 28px;
    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
  .recipe-img{
    padding-top: 20px;
    img{
      height: auto;
      width: 100%;
    }
  }
  // titles of linked recipes using related items at the bottom of the product page
  // .recipes-wrapper {
  //   margin-bottom: 20px;
  //   .recipe-h {
  //     font-weight: 600;
  //     font-size: 22px;
  //   }
  // }
  .recipe-table{
    width: 100%;
    td,th{
      font-size: 14px;
      margin: 2px 0px;
      padding: 5px 0px;
    }
    tr{
      border-bottom: 1px solid black;
    }
    .star{
      font-weight: 400;
    }
  }
  .recipe-info-txt{
    margin: 33px auto;
    span{
      font-weight: 400;
    }
  }
  .method-wrapper{
    margin-top: 55px;
    .recipe-h{
      margin-bottom: 20px;
    }
  }
  .method-list{
    padding-left: 20px;
    li{
      font-size: 14px;
      padding: 1px 0px;
    }
  }
  .method-info-txt{
    margin-top: 20px;
    font-weight: 800;
  }
}

// Search results page
#searchform {
  margin-top:3rem;
  margin-bottom: 3rem;
  #search-term {
    margin-top: 3rem;
  }
  .dropdown {
    padding:0 0.3rem 0 0.3rem;
  }
  #search-results{
    margin-top: 1rem;
    .result-title > a,
    .text-muted,
    .croppedDescription,
    cite {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .search-products a,
    .search-recipes a,
    .result-title > a {
      font-weight: 400;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }

    }
    .croppedDescription {
      font-size: 85%;
    }
    .pagination {
      margin-top: 2rem;
    }
  }
}

body.template-summary_view {

  h1.documentFirstHeading {
    text-align: left;
    color: #23063a !important;
  }
  .documentDescription {
    text-align: left;
    margin-bottom: 20px;
  }
  .feature-link {
    margin-top: 10px;
    margin-left: 4px;
    @include media-breakpoint-up(lg) {
      padding-left: 4px;
      padding-top: 10px;
    }
  }
  article.tileItem {
    border-top: 1px solid #23063a;
    margin-top: 16px;
    padding-top: 16px;
    h2.tileHeadline {
      margin-top: 10px;
      font-size: 23px;
      a {
        color: #23063a !important;
        text-decoration: none;
      }
    }
  }
  .tileBody {
    margin-top: 8px;
  }
}
