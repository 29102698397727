// @include media-breakpoint-up($nav-main-breakpoint) {
//   #content-header::before {
//     content: "main";
//     position: sticky;
//     top: 1em;
//     left: 0;
//   }
// }

// @include media-breakpoint-up(xs) {
//   body::before {
//     content: "xs";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

// @include media-breakpoint-up(sm) {
//   body::before {
//     content: "sm";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

// @include media-breakpoint-up(md) {
//   body::before {
//     content: "md";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

// @include media-breakpoint-up(lg) {
//   body::before {
//     content: "lg";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

// @include media-breakpoint-up(xl) {
//   body::before {
//     content: "xl";
//     position: sticky;
//     top: 0;
//     left: 0;
//   }
// }

// TODO
@media screen {
  .no-screen {
    display: none;
  }
}

/* Allow to swap 2 cells in a Mosaic row so that mobile and medium desktop have different ordering
of the cells. */
/* TODO re-enable this and check that this works.
See commit 19dcbc7d05a9cb0af114c1454b7d2d5a37a71083 on branch 1.x.
body.mosaic-grid {
  .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell:first-child {
    .make-sm-column-push(6);
  }
  .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell:last-child {
    .make-sm-column-pull(6);
  }
}
*/
/* Indicator in editor */
body.mosaic-enabled .mosaic-grid-row.mosaic-swap-cells-medium div.mosaic-grid-cell {
    border: 1px dotted red;
}
