.sub-page-header {
  position: relative;
  height: 225px;
  width: calc(100vw - 1px);
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  @include media-breakpoint-up(sm) {
    height: 250px;
  }
  @include media-breakpoint-up(md) {
    height: 335px;
  }
  @include media-breakpoint-up(lg) {
    height: 385px;
  }
  @include media-breakpoint-up(xl) {
    height: 450px;
  }
  @include media-breakpoint-up(xxl) {
    height: 500px;
  }

  .header-bg {
    overflow: hidden;
    z-index: 1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: 0 auto;
    margin-right: -8px;
    min-height: 100%;
    min-width: 100%;
    text-align: center;
    img {
      object-fit: cover;
      top: 0px;
      position: absolute;
      left: 50%;
      max-width: none;
      width: auto;
      transform: translate(-50%, 0);
      height: 100%;
      min-width: 100%;
    }
  }

  .header-content {
    position: absolute;
    height: 100%;
    left: 0%;
    top: 0px;
    z-index: 1;
    width: 49%;
    @include media-breakpoint-up(md) {
      height: auto;
      width: auto;
      left: 16%;
      top: 15%;
    }
    @include media-breakpoint-up(lg) {
      left: 18%;
    }
    @include media-breakpoint-up(xl) {
      left: 18%;
    }
    
    .header-h {
      background: $zee-yellow-trans;
      padding: 30px 20px 15px 20px;
      font-size: 25px;
      line-height: 28px;
      text-align: left;
      height: 100%;
      font-weight: 200;
      letter-spacing: 0px;
      strong {
        font-weight: 800;
      }
      @include media-breakpoint-up(md) {
        font-size: 36px;
        margin: 0px;
        padding: 25px 25px 25px 25px;
        letter-spacing: -2px;
        line-height: 40px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 46px;
        line-height: 46px;
        padding: 25px 25px 30px;
        font-weight: 200;
      }
      @include media-breakpoint-up(xl) {
        font-size: 55px;
        line-height: 55px;
        letter-spacing: -3px;
      }
    }
  }
}

.sub-page-header.no-image {
  height: 164px;
}